import { MallCode } from 'configs/malls'
import { EventCategory } from './type-utils'

export type ISO8601DateString = string

export type MallStat = {
  reviewsCount: number
  productsCount: number
  brandsCount: number
  merchantsCount: number
  categoriesCount: number
  lastObserved: number
}

export type Mall = {
  code: MallCode
  currency: string
  description: string | null
  notification: string | null
  stat: MallStat
}

export type Role = 'ROLE_USER' | 'ROLE_BRAND' | 'ROLE_MERCHANT' | 'ROLE_ADMIN' | 'ROLE_SUPER_ADMIN'

export type User = {
  id: number
  email: string
  fullName: string | null
  active: boolean
  activeUntil: ISO8601DateString | null
  activeMalls: MallCode[]
  roles: Array<Role>
  selectedMall: string
  phone: string
  switched: boolean
  maxMallApiKeys: number
  customColumns: {
    context: string
    column: string
  }[]
}

export type ProductStatus = 'deleted' | 'archived' | 'outstocked' | 'available' | 'unknown'

export type Category = {
  url: string | null
  level: number | null
  id: number
  name: string
  actual: boolean
  hasChildren: boolean | null
  pathIds: number[]
  active: boolean
}

export type Status = {
  name: ProductStatus
  ts: ISO8601DateString
  subStatuses: Status[]
}

export type Merchant = {
  id: number
  link: string
  name: string
  products: number | null
  createdAt: ISO8601DateString
  addedAt: ISO8601DateString
  changedAt: ISO8601DateString
  observedAt: ISO8601DateString
  rate: number | null
  inn: string | null
  ogrn: string | null
}

export type Brand = {
  id: number
  name: string
}

export type Subject = {
  id: number
  name: string
  level: number
  pathIds: number[]
  soldOrdersPercent: number | null
}

export type SubjectTree = Subject & {
  subjects: SubjectTree[]
}

export type ProductInfo = {
  id: number
  name: string
  mall: string
  mallUrl: string | null
  brand: Brand | null
  mpn: string | null
  merchants: Merchant[]
  images: string[]
  rate: number | null
  lastObserved: ISO8601DateString
  firstObserved: ISO8601DateString
  createdAt: ISO8601DateString
  categories: Category[]
  subjects: Subject[]
  currency: string
  statuses: Status[]
  minPrice: number | null
  maxPrice: number | null
  minMsrp: number | null
  maxMsrp: number | null
  stock: number | null
  reviews: number | null
  reviewsCaptured: number | null
  ratingsCaptured: number | null
  orders: number | null
  inventoryTypes: InventoryType[]
  variations: ProductVariation[]
  variantsCount: number | null
  retailerId: number
  cmmf: string | null
  groupedIds: number[]
}

export type FavoriteList = {
  id: number
  name: string
  mall: string
  updatedAt: ISO8601DateString
  createdAt: ISO8601DateString
}

export type MetricsAggregate = {
  date: ISO8601DateString
  sales: number | null
  gmv: number | null
  reviewsCount: number | null
  reviewsRate: number | null
}

export type MetricsComparison = {
  current: MetricsAggregate | null
  previous: MetricsAggregate | null
}

export const MetricsChartTypes = [
  'gmv',
  'sales',
  'prices',
  'msrp',
  'inventory',
  'rating',
  'review',
  'orders',
  'mallReviews',
  'lostProfit',
] as const

export type MetricsChartType = (typeof MetricsChartTypes)[number]

export const OfferChartTypes = [
  'gmv',
  'sales',
  'prices',
  'msrp',
  'inventory',
  'lostProfit',
] as const

export type OfferChartType = (typeof OfferChartTypes)[number]

export const WarehouseChartTypes = ['gmv', 'sales', 'inventory'] as const

export type WarehouseChartType = (typeof WarehouseChartTypes)[number]

export const VariantChartTypes = ['gmv', 'sales', 'prices', 'inventory'] as const

export type VariantChartType = (typeof VariantChartTypes)[number]

export type ChartDataPoint = {
  date: ISO8601DateString
  value: number
}

export type PriceChartDataPoint = ChartDataPoint & { min: number; max: number }

export type FeedChartDataPoint = ChartDataPoint & { ad: boolean }

export type ContentChartDataPoint = {
  date: ISO8601DateString
  wordsName: number
  wordsDesc: number
  images: number
  videos: number
  characteristics: number
}

export type FeedChartPositionsRequest = {
  feedsIds: number[]
  feedType: keyof typeof FeedTypes
  from?: ISO8601DateString
  to?: ISO8601DateString
}

export type FeedChartPositions = {
  feedId: number
  positions: FeedChartDataPoint[]
}

export type ContentStat = {
  titleWordsCount: number
  descriptionWordsCount: number
  imagesCount: number
  videosCount: number
  pts: number
  characteristicsCount: number
}

export const ContentChartTypes = [
  'title',
  'description',
  'images',
  'videos',
  'characteristics',
] as const

export type ContentChartType = (typeof ContentChartTypes)[number]

export type ContentStats = {
  reference: ContentStat
  product: ContentStat
}

export type FeedType = 'category' | 'store' | 'phrase' | 'brand'

export const FeedTypes = {
  1: 'category',
  2: 'store',
  3: 'phrase',
  4: 'brand',
  6: 'banner',
} as const

export const ReverseFeedTypes = {
  category: 1,
  store: 2,
  phrase: 3,
  brand: 4,
} as const

export type Feed = {
  feedType: keyof typeof FeedTypes
  feedId: number
  entityId: number | null
  productId: number
  feedName: string
  position: number
  archived: ISO8601DateString
  rank: number | null
  searchVolume: number | null
  totalProducts: number | null
  competition: number | null
  feedPath: Category[]
  url: string | null
}

export type ChartData = [Date, number][]
export type FeedChartData = [Date, number, boolean][]

export type ProductVariation = {
  id: number
  title: string
  externalId: string
  price: number | null
  maxPrice: number | null
  msrp: number | null
  maxMsrp: number | null
  imageUrl: string | null
  color: string | null
  size: string | null
  stock: number | null
  deleted: ISO8601DateString | null
  inventoryTypes: InventoryType[]
  warehouses: {
    id: string
    name: string
    stock: number
  }[]
  properties: {
    id: number
    name: string
    value: string
  }[]
}

export type ProductVariationAggregates = {
  variationId: number
  sales: number | null
  gmv: number | null
}

export type Country = {
  code: string
  products: number
  reviews: number
}

export type ProductReviewFilter = {
  rateFrom?: number
  rateTo?: number
  country?: string
  firstAnswerFrom?: ISO8601DateString
  firstAnswerTo?: ISO8601DateString
  createdFrom?: ISO8601DateString
  createdTo?: ISO8601DateString
  answer?: 'all' | 'with_answer' | 'without_answer'
  sort?: `review_${'created' | 'rate' | 'country'}_${'asc' | 'desc'}`
  limit?: number
  after?: number
}

export type ProductReview = {
  id: string
  productId: number
  createdAt: ISO8601DateString
  rate: number | null
  text: string | null
  countryCode: string | null
  answersInfo: {
    count: number
    firstAnswer: ISO8601DateString
    lastAnswer: ISO8601DateString
    allAnswersTimes: ISO8601DateString[]
  } | null
  answers:
    | {
        createdAt: ISO8601DateString
        text: string
      }[]
    | null
}

export type ReviewsByStar = {
  star: string
  reviews: number
}

export type ReviewsByCountry = {
  title: string
  id: string
  count: number
}

export type TimelineEvent = {
  name: 'available' | 'outstocked' | 'archived' | 'deleted' | 'created' | 'added'
  ts: ISO8601DateString
}

export type BrandGroup = {
  name: string
  brandType: 'own' | 'competitor' | 'other'
  mall: string
  brandsIds: number[]
  updatedAt: ISO8601DateString
  createdAt: ISO8601DateString
}

export type DecryptedBrandIds = {
  name: string
  id: number
}

export type DecryptedBrandGroup = {
  name: string
  brandType: 'own' | 'competitor' | 'other'
  mall: string
  brandsIds: DecryptedBrandIds[]
  updatedAt: ISO8601DateString
  createdAt: ISO8601DateString
}

export type SubscriptionMall = {
  id: number
  mall: string
  price: number
  nextPrice: number
  renew: boolean
  updatedAt: ISO8601DateString
  createdAt: ISO8601DateString
}

export type Subscription = {
  id: number
  activeUntil: ISO8601DateString | null
  malls: SubscriptionMall[]
  state: 'created' | 'active' | 'stopped' | 'canceled'
  period: 'month' | '3_months' | '6_months' | 'year'
  updatedAt: ISO8601DateString
  createdAt: ISO8601DateString
  currency: string
  nextBillAmount: number
  cardMask: string | null
  linkedCard: true
  trial: boolean
}

export type Tariff = {
  mall: MallCode
  name: string
  products: number
  brands: number
  merchants: number
  pricePerMonth: number
  pricePer3Months: number
  pricePer6Months: number
  pricePerYear: number
}

export type SubscriptionTariffs = {
  trial: boolean
  tariffs: Tariff[]
}

export type SubscriptionPeriod = 'month' | '3_months' | '6_months' | 'year'

export type SubscriptionRequest = {
  malls: MallCode[]
  period: SubscriptionPeriod
  promo?: string
}

export type SubscriptionResponse = {
  id: number
  paymentUrl: string
}

export type ExportListItem = {
  id: number
  hidden: boolean
  mall: string
  state: 'waiting' | 'running' | 'complete' | 'failed'
  type: string
  updatedAt: ISO8601DateString
  createdAt: ISO8601DateString
  totalCount: number
  exportedCount: number
}

export type ExportList = {
  page: number
  pageSize: number
  totalCount: number
  items: ExportListItem[]
}

export type BLArticle = {
  id: number
  name: string
  tag: string
  slug: string
  text: string
  description: string
  active: boolean
  image: {
    original: string
    big: string
  }
  lang: string
  updatedAt: ISO8601DateString
  createdAt: ISO8601DateString
  imageUrl: string
}

export type BLArticleList = {
  page: number
  pageSize: number
  totalCount: number
  items: BLArticle[]
}

export type MercureMessage = {
  event: string
  data: object
}

export type ExportMercureMessage = {
  event: EventCategory<'export', 'started' | 'finished' | 'error'>
  data: {
    reportId: number
  }
}

export type ProductOfferProperty = {
  id: number
  name: string
  value: string
}

export type InventoryType = 'unknown' | 'marketplace' | 'merchant' | 'express' | 'dbs'

export type ProductOfferInfo = {
  productId: number
  merchantId: number | null
  variationId: number
  name: string
  image: string | null
  inventory: number | null
  price: number | null
  msrp: number | null
  lastObserved: ISO8601DateString
  deleted: boolean
  properties: ProductOfferProperty[]
  inventoryType: InventoryType
}

export type ProductOfferMerchant = {
  merchantId: number
  minPrice: number
  maxPrice: number
  minMsrp: number
  maxMsrp: number
  inventory: number
  merchant: Merchant
  offers: ProductOfferInfo[]
}

export type ProductOfferVariation = {
  variationId: number
  variationName: string
  minPrice: number
  maxPrice: number
  minMsrp: number
  maxMsrp: number
  inventory: number
  offers: ProductOfferInfo[]
}

export type ProductOffers = {
  id: number
  merchants: ProductOfferMerchant[]
  variations: ProductOfferVariation[]
}

export type AutocompleteSuggestion = {
  id: number
  name: string
}

export type CategoryTree = Category & {
  categories: CategoryTree[]
}

export type BusinessApiKey = {
  id: number
  mall: string
  name: string
  key: string
  innerAnalyticsId: number
  autoreplierEnabled: boolean
  autoreplierId: number | null
  autoreplierApiKey: string | null
  bidderId: number | null
  bidderApiKey: string | null
  inn: string | null
  marketplaceSellerId: string | null
  updatedAt: ISO8601DateString
  createdAt: ISO8601DateString
}

export type BusinessApiKeyInfo = {
  id: number
  sellerId: number
  marketplaceCode: string
  apiKey: string
  enabled: boolean
  expired: boolean
  hasNullPrimeCosts: boolean
  updatedAt: ISO8601DateString
  createdAt: ISO8601DateString
  lastSyncedAt: ISO8601DateString | null
  firstRecordDatetime: ISO8601DateString | null
}

export type SellersWorkScheme = 'fbo' | 'fbs' | null

export const BusinessSalesStatTypes = ['price', 'percent', 'count'] as const

export type BusinessSalesStatPriceAndCount = {
  price: number | null
  count: number | null
  percent: number | null
}

export type BusinessSalesStatPriceAndPercent = {
  price: number | null
  percent: number | null
  count: number | null
}

export type BusinessSalesCurrentOrPreviousStats = {
  payedAmount: BusinessSalesStatPriceAndCount
  profitAmount: BusinessSalesStatPriceAndPercent
  netProfitAmount: BusinessSalesStatPriceAndPercent
  orderedAmount: BusinessSalesStatPriceAndCount
  expensesAmount: BusinessSalesStatPriceAndPercent
  primeCostExpenses: BusinessSalesStatPriceAndPercent
  compensationAmount: BusinessSalesStatPriceAndPercent
  commission: BusinessSalesStatPriceAndPercent
  storage: BusinessSalesStatPriceAndPercent
  adExpenses: BusinessSalesStatPriceAndPercent
  fines: BusinessSalesStatPriceAndPercent
  paidAcceptance: BusinessSalesStatPriceAndPercent
  deduction: BusinessSalesStatPriceAndPercent
  logisticToClient: BusinessSalesStatPriceAndCount
  logisticFromClient: BusinessSalesStatPriceAndCount
  returnedAmount: BusinessSalesStatPriceAndCount
  stock: BusinessSalesStatPriceAndCount
  stockFull: BusinessSalesStatPriceAndCount
  stockToClient: BusinessSalesStatPriceAndCount
  stockFromClient: BusinessSalesStatPriceAndCount
  stockOnWay: BusinessSalesStatPriceAndCount
  cancelledAmount: BusinessSalesStatPriceAndCount
  additionalService: BusinessSalesStatPriceAndPercent
  cancelledAmountFromOrders: BusinessSalesStatPriceAndPercent
}

export type CommonOrdersStat = {
  period: {
    from: ISO8601DateString
    to: ISO8601DateString
  }
  stat: {
    orders: BusinessSalesStatPriceAndCount
    soldOrders: BusinessSalesStatPriceAndCount
    returnedOrders: BusinessSalesStatPriceAndCount
    cancelledOrders: BusinessSalesStatPriceAndCount
    deliveringOrders: BusinessSalesStatPriceAndCount
  }
}

export type BusinessSalesStats = {
  current: BusinessSalesCurrentOrPreviousStats
  previous: BusinessSalesCurrentOrPreviousStats
  commonOrdersStat: CommonOrdersStat
}

export type BusinessSalesProductStats = {
  price: number | null
  count: number | null
  percent: number | null
}

export type BusinessSalesLostProductStats = {
  deliveryTimeExceeded: '2 weeks' | '1 month' | '2 months'
  lostProductsCount: number
  articles: number[]
}

export const BusinessSalesLostProductStatsPeriodType = ['2 weeks', '1 month', '2 months'] as const

export type BusinessSalesProductListFilter = {
  from: string
  to: string
  after: number
  limit: number
  brandId: number[]
  article: string[]
  name?: string
  sort?: string
  revenueAbcGroup?: string
  profitAbcGroup?: string
}

export type BusinessSalesProductRowStats = {
  orderedAmount: BusinessSalesProductStats
  payedAmount: BusinessSalesProductStats
  returnedAmount: BusinessSalesProductStats
  expensesAmount: BusinessSalesProductStats
  profitAmount: BusinessSalesProductStats
  commission: BusinessSalesProductStats
  compensationAmount: BusinessSalesProductStats
  storage: BusinessSalesProductStats
  logistic: BusinessSalesProductStats
  deliveryLogistic: BusinessSalesProductStats
  returnLogistic: BusinessSalesProductStats
  deliveryUnitLogisticPrice: number | null
  returnUnitLogisticPrice: number | null
  ad: BusinessSalesProductStats
  fines: BusinessSalesProductStats
  paidAcceptance: BusinessSalesProductStats
  deduction: BusinessSalesProductStats
  stock: BusinessSalesProductStats
  stockToClient: BusinessSalesProductStats
  stockFromClient: BusinessSalesProductStats
  stockOnWay: BusinessSalesProductStats
  supplyRecommendation: {
    count: number
    daysSalesOfInventory: number
  }
  profitAbcAnalysis: {
    group: string
    percent: number
  }
  revenueAbcAnalysis: {
    group: string
    percent: number
  }
  commonOrdersStat: CommonOrdersStat
  returnOnInvestmentPercent: number | null
  profitabilityPercent: number | null
  marginalityPercent: number | null
}

export type BusinessSalesProductSize = BusinessSalesProductRowStats & {
  article: string
  size: string
  barcodes: string[]
  primeCost: number | null
}

export type BusinessSalesProduct = BusinessSalesProductRowStats & {
  id: number
  removed: boolean
  name: string
  brand: string
  article: string
  supplierArticle: string
  image: string
  url: string
  rating: number
  reviewsCount: number
  barcodes: string[]
  primeCost: { start: number; end: number } | null
  sizes: BusinessSalesProductSize[]
}

export type BusinessSalesTableRow = BusinessSalesProduct | BusinessSalesProductSize

export type AbcGroupSizes = { a: number; b: number; c: number; d: number }

export type BusinessSalesProductList = {
  products: BusinessSalesProduct[]
  total: number
  profitAbcGroupSizes: AbcGroupSizes
  revenueAbcGroupSizes: AbcGroupSizes
}

export type BusinessSalesChartData = {
  ts: ISO8601DateString
  ordersPrice: number | null
  ordersCount: number | null
  salesPrice: number | null
  salesCount: number | null
  returnsPrice: number | null
  returnsCount: number | null
  cancelsPrice: number | null
  cancelsCount: number | null
  primeCostExpensesPrice: number | null
  deliveryLogisticPrice: number | null
  returnLogisticPrice: number | null
  commissionPrice: number | null
  compensationPrice: number | null
  storagePrice: number | null
  otherExpensesPrice: number | null
  expensesPrice: number | null
  profitPrice: number | null
}

export type BusinessSalesBrand = {
  id: number
  name: string
}

export type BusinessSalesData = {
  apiKeys: BusinessApiKey[]
  apiKeyInfo: BusinessApiKeyInfo | null
  apiKeysLoading: boolean
  stats: BusinessSalesStats | null
  statsLoading: boolean
  statsChartData: BusinessSalesChartData[] | null
  startsChartDataLoading: boolean
  lostProductStats: BusinessSalesLostProductStats[]
  lostProductStatsLoading: boolean
  products: BusinessSalesProduct[]
  productsTotalCount: number
  profitAbcGroupSizes: AbcGroupSizes
  revenueAbcGroupSizes: AbcGroupSizes
  productsLoading: boolean
  brands: BusinessSalesBrand[]
  brandsLoading: boolean
  hasNullPrimeCosts: boolean
  primeCostsHaveBeenChanged: boolean
  sellersWorkScheme: SellersWorkScheme
}

export type BusinessSalesPeriodFilter = {
  periodType: string
  dateFrom: string
  dateTo: string
}

export type BusinessSalesProductFilter = {
  productsAfter: number
  productsLimit: number
  brands: number[]
  articles: string[]
  name?: string
  revenueAbcGroup?: AbcGroup
  profitAbcGroup?: AbcGroup
}

export type BusinessReviewsAccountInfo = {
  clientId: number
  marketplaceCode: string
  accountId: number | null
  apiToken: string | null
  inn: string | null
  id: number
  answersCount: number
  enabled: boolean
  createdAt: ISO8601DateString
  updatedAt: ISO8601DateString
  expired: boolean
  lastReplyTime: ISO8601DateString | null
}

export type BusinessReviewsStopword = {
  id: number
  text: string
  apiKeyId: number
  updatedAt: ISO8601DateString | null
}

export type BusinessReviewsTemplate = {
  id: number
  title: string
  text: string
  apiKeyId: number
  brandsId: string[] | null
  productsId: string[] | null
  rulesId: number[] | null
  answersCount: number
  updatedAt: ISO8601DateString | null
}

export type BusinessReviewsRule = {
  id: number
  title: string
  apiKeyId: number
  brandsId: string[] | null
  productsId: string[] | null
  templatesId: number[] | null
  accountableRate: number[] | null
  enabled: boolean
  updatedAt: ISO8601DateString | null
  answersCount: number
  replyToNonEmptyReviews?: boolean
  replyToEmptyReviews?: boolean
}

export type BusinessReviewsBrand = {
  id: number
  externalId: string
  name: string
}

export type BusinessReviewsBrandList = {
  id: number
  list: BusinessReviewsBrand[] | null
  loading: boolean
}

export type BusinessReviewsProduct = {
  id: number
  name: string | null
  brand: string
  article: string
  image: string
  url: string
  rating: number
  reviewsCount: number
}

export type BusinessReviewsProductList = {
  id: number
  list: BusinessReviewsProduct[] | null
  loading: boolean
}

export type BusinessReviewsData = {
  apiKeys: BusinessApiKey[]
  apiKeysLoading: boolean
  accesses: BusinessApiKey[]
  reviewsAccounts: BusinessReviewsAccountInfo[]
  stopwords: BusinessReviewsStopword[]
  stopwordsLoading: boolean
  templates: BusinessReviewsTemplate[]
  templatesLoading: boolean
  rules: BusinessReviewsRule[]
  rulesLoading: boolean
  products: BusinessReviewsProductList[]
  brands: BusinessReviewsBrandList[]
}

export type BusinessReviewsOnboarding = {
  accesses: boolean
  stopwords: boolean
  templates: boolean
  rules: boolean
}

export type BusinessReviewsApiKeyStat = {
  id: number
  answersCount: number | null
  loading: boolean
}

export type BusinessReviewsStopwordFilter = {
  apiKeyId?: number
  text?: string
}

export type BusinessReviewsTemplateFilter = {
  apiKeyId?: number
  title?: string
  productsId?: string[]
  brandsId?: string[]
}

export type BusinessReviewsRuleFilter = {
  apiKeyId?: number
  title?: string
  productId?: string
  brandId?: string
  templateId?: number
  enabled?: boolean
}

export type BusinessReviewsDefaultSort = {
  key: string
  order: string
}

export const BusinessReviewsFormTypeValue = ['all', 'brands', 'products'] as const

export type BusinessReviewsPagination = {
  activePage: number
  pageSize: number
}

export type BidderPageData = {
  apiKeys: BusinessApiKey[]
  accountsWithAdsKey: BusinessApiKey[]
  adsAccounts: BidderAccountInfo[]
  selectedApiKey: BusinessApiKey | null
}

export type BidderAccountInfo = {
  id: number
  externalClientId: number
  marketplaceCode: MallCode
  apiKey: string
  enabled: boolean
  expired: boolean
  createdAt: ISO8601DateString
  updatedAt: ISO8601DateString
}

export type Interval = {
  start: number
  end: number
}

export type DayOfWeek = 'monday'

export type BidderCampaignType = 'auto' | 'catalog_and_search'

export type BidderCampaignStatus = 'ready_to_start' | 'ended' | 'running' | 'on_pause' | 'refused'

export type BidderStatus =
  | 'disabled'
  | 'runningForBidManagement'
  | 'runningForBidChecking'
  | 'runningWithFixedBidPrice'
  | 'waitingForParamsCorrection'
  | 'waitingForScheduleConditions'
  | 'waitingForBidPriceConditions'

export type BidderCampaignListItem = {
  id: number
  externalId: string
  marketplaceAccountId: number
  name: string
  type: BidderCampaignType
  status: BidderCampaignStatus
  budget: number
  dailyBudget: number
  bidPrice: number | null
  maxBidPrice: number | null
  note: string | null
  sendNotifications: boolean
  bidderEnabled: boolean
  createdAt: ISO8601DateString
  updatedAt: ISO8601DateString | null
  startedAt: ISO8601DateString | null
  endedAt: ISO8601DateString | null
  bidderLastWorkedAt: ISO8601DateString | null
  viewsCount: number
  clicksCount: number
  cartAdditionsCount: number
  ordersCount: number
  orderedProductsCount: number
  totalOrdersPrice: number
  totalAdExpensesPrice: number
  clickThroughRate: number
  costPerClick: number
  conversionRate: number
  adExpensesPercent: number | null
  bidderStatus: BidderStatus
  stoppingReason:
    | 'UnsupportedCampaignType'
    | 'InappropriateCampaignStatus'
    | 'InappropriateProductsCount'
    | 'InappropriateActivePhrasesCount'
    | 'InappropriateEnabledDisplayZones'
    | 'ZeroBudget'
    | 'MaxBidPriceIsBelowMinAllowable'
    | 'MissingMaxBidPrice'
    | 'MissingTargetPositionInterval'
    | 'NoActiveTimeIntervalsInActivitySchedule'
    | 'BidPriceExceededMaxBidPrice'
    | 'DisabledByActivitySchedule'
    | 'MissingActivePresets'
    | 'MissingMasterClusters'
    | 'ExceededMasterClustersCount'
    | null
}

export type DisplayZones = {
  productCard: boolean
  searchOrCatalog: boolean
}

export type ManagementType = 'masterPresets' | 'masterAndActivePresets' | 'allPresets'

export type FallbackBidStrategy = 'stopCampaign' | 'setFixedBid'

export type TargetPositionCluster = 'best' | 'worst'

export type BidderRegion = {
  id: string
  name: string
  address: string
}

export type BidderSyncResponse = {
  lastSyncedAt: number
}

export type BidderCampaignProduct = {
  sku: string
  deliveryTimeInHours: number
  name: string | null
  image: string | null
  brandName: string | null
  merchantName: string | null
  price: number | null
  msrp: number | null
  sales: number | null
  gmv: number | null
  rating: number | null
  reviewsCount: number | null
}

export type BidderCampaignInfo = BidderCampaignListItem & {
  subjectName: string | null
  setName: string | null
  menuName: string | null
  displayZones: DisplayZones | null
  bidIncreaseValue: number | null
  targetPositionInterval: Interval | null
  fallbackBidStrategy: FallbackBidStrategy
  fixedBidPrice: number
  targetPositionCluster: TargetPositionCluster
  activitySchedule: Record<DayOfWeek, Interval[]> | null
  region: BidderRegion
  lastBidderStat: BidderCampaignBidderStat | null
  managementType: ManagementType
  products: BidderCampaignProduct[] | null
  lastSyncedAt: number | null
  keywordsLastSyncedAt: number | null
  statLastSyncedAt: number | null
}

export type BidderCampaignUpdateInfo = Partial<{
  name: string
  note: string
  displayZones: DisplayZones
  fallbackBidStrategy: FallbackBidStrategy
  fixedBidPrice: number
  targetPositionCluster: TargetPositionCluster
  maxBidPrice: number
  bidIncreaseValue: number
  targetPositionInterval: Interval
  bidderEnabled: boolean
  activitySchedule: Record<DayOfWeek, Interval[]>
  managementType: ManagementType | null
  regionId: string | null
  productSkus: string[]
}>

export type BidderCampaignListFilter = {
  marketplaceAccountId?: number
  name?: string
  type?: BidderCampaignType
  status?: BidderCampaignStatus[]
  sort?: ListSort<
    | 'name'
    | 'bidPrice'
    | 'createdAt'
    | 'bidderEnabled'
    | 'totalAdExpensesPrice'
    | 'clickThroughRate'
  >
}

export type AbcGroup = 'A' | 'B' | 'C' | 'D'

export type BidderKeywordMetrics = {
  viewsCount: number | null
  searchVolume: number | null
  clicksCount: number | null
  adExpensesPrice: number | null
  clickThroughRate: number | null
  abcGroup: AbcGroup
  abcPercent: number
}

export type BidderKeyword = BidderKeywordMetrics & {
  name: string
  productsCount: number | null
}

export type BidderCluster = BidderKeywordMetrics & {
  id: number
  normalForm: string
  isMasterCluster: boolean
  keywords: BidderKeyword[]
}

export type BidderPresetStatus = 'activePreset' | 'minusPreset'

export type BidderPreset = BidderKeywordMetrics & {
  id: number
  externalId: string
  name: string
  userStatus: BidderPresetStatus
  activeStatus: BidderPresetStatus
  clusters: BidderCluster[]
}

export type BidderCampaignKeywords = {
  presets: BidderPreset[]
  nonPresetClusters: BidderCluster[] | null
}

export type BidderCampaignKeywordsUpdate = { activePresetIds: number[]; masterClusterIds: number[] }

export type BidderClusterMetrics = {
  clusterName: string
  viewsCount: number | null
  position: number | null
  organicPosition: number | null
}

export type BidderCampaignStat = {
  ts: ISO8601DateString
  clusters: BidderClusterMetrics[] | null
  viewsCount: number
  clicksCount: number
  uniqueUsersCount: number
  cartAdditionsCount: number
  ordersCount: number
  orderedProductsCount: number
  totalOrdersPrice: number
  totalAdExpensesPrice: number
  viewsToUniqueUsersRatio: number
  clickThroughRate: number
  costPerClick: number
  conversionRate: number
  position: number | null
  organicPosition: number | null
  bidPrice: number | null
}

export type BidderCampaignStatSummary = {
  viewsCount: number
  clicksCount: number
  uniqueUsersCount: number
  cartAdditionsCount: number
  ordersCount: number
  orderedProductsCount: number
  totalOrdersPrice: number
  totalAdExpensesPrice: number
  viewsToUniqueUsersRatio: number
  clickThroughRate: number
  costPerClick: number
  conversionRate: number
  adExpensesPercent: number | null
  avgBidPrice: number | null
  avgPosition: number | null
  avgOrganicPosition: number | null
}

export type BidderCampaignBidderStat = {
  ts: ISO8601DateString
  clusters: BidderClusterMetrics[]
  bidPrice: number | null
  startTargetPosition: number | null
  endTargetPosition: number | null
  maxBidPrice: number | null
  fallbackBidStrategy: FallbackBidStrategy
  fixedBidPrice: number
  targetPositionCluster: TargetPositionCluster
  bidderStatus: BidderStatus
}

export type BidderCampaignBidDistributionRequest = {
  feedId: string
  feedType: string
  regionId: string
  adType?: BidderCampaignType
}

export type BidderCampaignBidDistribution = {
  sku: string
  deliveryTimeInHours: number | null
  page: number
  position: number
  organicPosition: number
  bidPrice: number | null
  belongsToCampaign: boolean
  externalAdCampaignId: string | null
  name: string | null
  image: string | null
  url: string | null
  brandName: string | null
  merchantName: string | null
  price: number | null
  msrp: number | null
  sales: number | null
  gmv: number | null
  rating: number | null
  reviewsCount: number | null
  adType: BidderCampaignType | null
}

export type BidderCampaignBidPrediction = {
  position: number
  bidPrice: number
}

export type BidderProductCurrentPosition = {
  adPosition: string | null
  organicPosition: string | null
  bidPrice: number | null
}

export type SupplyPlanningPageData = {
  apiKeys: BusinessApiKey[]
  apiKeyInfoList: BusinessApiKeyInfo[]
  currentSettings: SupplyRecommendationSettings
  availableSettings: SupplyRecommendationAvailableSettings
  currentReport: SupplyRecommendationReport | null
}

export type SupplyRecommendationSettings = {
  marketplaceAccountId?: number
  periodType: 'interval' | 'lastDays'
  from: ISO8601DateString
  to: ISO8601DateString
  lastDaysCount: number
  supplyDate: ISO8601DateString | null
  warehouseLoadingDays: number
  supplyCorrectionPercent: number
  calcType: SupplyRecommendationType
  warehouseIds: number[] | null
  supplyRegionIds: number[] | null
  createdAt: ISO8601DateString
  updatedAt: ISO8601DateString
}

export type SupplyRecommendationAvailableSettings = {
  warehouses: { id: number; name: string }[]
  supplyRegions: { id: number; name: string; warehouses: { id: number; name: string }[] }[]
}

export type SupplyRecommendationType = 'simple' | 'byWarehouses' | 'byRegions'

export type SupplyRecommendationRequest = {
  marketplaceAccountId?: number
  supplyDate?: ISO8601DateString | null
  from: ISO8601DateString
  to: ISO8601DateString
  lastDaysCount: number
  warehouseLoadingDays: number
  supplyCorrectionPercent: number
  calcType: SupplyRecommendationType
  warehouseIds?: number[] | null
  supplyRegiodIds?: number[] | null
}

export type SupplyRecommendationRow =
  | SupplyRecommendationProduct
  | SupplyRecommendationSize
  | SupplyRecommendationWarehouse
  | SupplyRecommendationRegion

export type SupplyRecommendationCalculations = {
  availableStocksCount: number
  deliveringStocksCount: number
  returningStocksCount: number
  salesCount: number
  salesPercent: number
  returnsCount: number
  cancelsCount: number
  salesPerDay: number
  returnsPerDay: number
  cancelsPerDay: number
  returnDeliveryDuration: number | null
  turnoverDays: number
  stocksOnSupplyDate: number | null
  salesOnSupplyDate: number | null
  stocksForWarehouseLoading: number
  stocksEndAt: number | null
  needSupplyUnits: number
  boxMultiplicity: number
  needSupplyBoxes: number
  supplyPrice: number | null
}

export type SupplyRecommendationWarehouse = SupplyRecommendationCalculations & {
  warehouseId: number
  warehouseName: string
}

export type SupplyRecommendationRegion = SupplyRecommendationCalculations & {
  supplyRegionId: number
  supplyRegionName: string
  supplyRegions?: SupplyRecommendationRegion[]
}

export type SupplyRecommendationSize = SupplyRecommendationCalculations & {
  size: string
  barcodes: string[]
  supplyRegions: SupplyRecommendationRegion[] | null
  warehouses: SupplyRecommendationWarehouse[] | null
}

export type SupplyRecommendationProduct = SupplyRecommendationCalculations & {
  id: number | null
  name: string | null
  marketplaceAccountId: number
  brand: string | null
  sku: string
  supplierSku: string
  image: string | null
  url: string
  rating: number | null
  primeCost: number | null
  sizes: SupplyRecommendationSize[]
}

export type SupplyRecommendationSummary = {
  needSupplyUnits: number
  supplyPrice: number | null
  availableProducts: number | null
  outOfStockProducts: number | null
  willBeOutOfStockProducts: number | null
  needSupplyProducts: number | null
  warehouses: {
    warehouseId: number
    warehouseName: string
    availableStocksCount: number
    ordersCount: number
    salesCount: number
    needSupplyUnits: number
  }[]
  supplyRegions: {
    supplyRegionId: number
    supplyRegionName: string
    availableStocksCount: number
    ordersCount: number
    salesCount: number
    needSupplyUnits: number
  }[]
}

export type SupplyRecommendationReport = {
  summary: SupplyRecommendationSummary
  products: SupplyRecommendationProduct[]
}

export type BusinessUnitEconomicsCalculations = {
  price: number
  fullPrice: number
  discount: number
  discountAmount: number
  discountPrice: number
  maxDiscountBeforeZeroProfit: number
  quantity: number
  priceOfDelivery: number
  commissionPercent: number
  commissionAmount: number
  processingOfGoods: number
  promotionExpensesPercent: number
  promotionExpensesAmount: number
  storing: number
  conversion: number
  expectedReverseLogisticsCost: number
  delivery: number
  grossProfit: number
  usnPercent: number
  usnAmount: number
  profitPerGoodPercent: number
  profitPerGoodAmount: number
  profitPerGoodsAmount: number
  revenuePerShipment: number
  length: number
  width: number
  height: number
  volume: number
}

export type MarketplaceCommission = {
  id: number
  marketplaceCode: MallCode
  category: string
  subject: string
  marketplaceWarehousePercent: number
  supplierWarehouseToMarketplacePercent: number
  supplierWarehouseSelfPercent: number
  supplierWarehouseSelfExpressPercent: number
  createdAt: ISO8601DateString
  deletedAt: ISO8601DateString
}

export type WarehouseCoefficient = {
  id: number
  name: string
  type: string
  marketplaceCode: MallCode
  coefficientPercent: number
  logisticPerLiter: number
  logisticExtraLiter: number
  storagePerUnit: number
  storageExtraUnit: number
  createdAt: ISO8601DateString
  deletedAt: ISO8601DateString
}

export type PrimeCostResponse = {
  article: string
  primeCost: number
}

export type ProductNameVersion = {
  name: string
  ts: ISO8601DateString
}

export type ProductDescriptionVersion = {
  version: ISO8601DateString
  text: string
}

export type StartExportResponse =
  | { id: number }
  | { error: 'ExportLimitReached'; values: { interval: number; max: number } }

type RangeFilter<T extends string> = {
  [key in `${T}${'From' | 'To'}`]?: number
}

type DateRangeFilter<T extends string> = {
  [key in `${T}${'From' | 'To'}`]?: ISO8601DateString
}

export const rangeFilters = [
  'minPrice',
  'maxPrice',
  'minMsrp',
  'maxMsrp',
  'gmvGrow',
  'salesGrow',
  'inventory',
  'review',
  'reviewGrow',
  'ratingsCaptured',
  'rate',
  'order',
] as const
export const dateRangeFilters = [
  'created',
  'observed',
  'reviewFirst',
  'reviewLast',
  'available',
  'outstocked',
  'archived',
  'deleted',
] as const

export type ProductRangeFilterType =
  | (typeof rangeFilters)[number]
  | (typeof dateRangeFilters)[number]

type ListSort<T extends string> = `${T}_asc` | `${T}_desc`

export type ProductListFilter = {
  name?: string
  mpn?: string
  cmmf?: string

  urls?: string[]
  retailerIds?: string[]

  periodType?: 'grow' | 'calendar'
  periodMeasure?: 'D' | 'W' | 'M' | 'Y'
  periodNumber?: string

  statuses?: ProductStatus[]
  inventoryTypes?: InventoryType[]
  countries?: string[]
  sort?:
    | ListSort<
        | 'created'
        | 'added'
        | 'observed'
        | 'deleted'
        | 'rate'
        | 'discount'
        | `discount_${'min' | 'avg' | 'max'}`
        | `msrp_${'min' | 'avg' | 'max'}`
        | 'likes'
        | `price_${'min' | 'avg' | 'max'}`
        | 'review'
        | `review_${'first' | 'last' | 'grow'}`
        | 'mall_reviews'
        | 'rating_count'
        | 'order'
        | 'order_grow'
        | 'sales'
        | 'sales_grow'
        | 'gmv'
        | 'gmv_grow'
        | 'id'
        | 'inventory'
        | `inventory_${'sum' | 'count' | 'min' | 'max'}`
      >
    | 'id'
    | 'gmv'
    | 'sales'
    | 'order'
    | 'review'
    | 'likes'
    | 'rate'

  categoriesIds?: number[]
  subjectsIds?: number[]
  merchantsIds?: number[]
  brandsIds?: number[]

  limit?: number
  after?: number
} & RangeFilter<(typeof rangeFilters)[number]> &
  DateRangeFilter<(typeof dateRangeFilters)[number]>

export type ProductListItemsSummary = {
  total: number
  reviews: number
  avgRate: number
  avgPrice: number
  soldOrdersPercent: number
  sales: number
  gmv: number
}

export type AggregatePriceInfo = {
  min: number | null
  avg: number | null
  max: number | null
}

export type AggregatePropertyInfo = {
  total: number | null
}

export type ProductListItemPeriod = {
  orders: number | null
  reviews: number | null
  gmv: number | null
  sales: number | null
}

export type LegacyProductInfo = {
  id: number
  categories: Category[]
  variations: ProductVariation[]
  stock: number | null
  isStockAvailable: boolean | null
  likes: number | null
  reviewsCount: number | null
  sales: AggregatePropertyInfo | null
  gmv: AggregatePropertyInfo | null
  review: AggregatePropertyInfo | null
  question: AggregatePropertyInfo | null
  reviewCaptured: AggregatePropertyInfo | null
  order: AggregatePropertyInfo | null
  lastObserved: ISO8601DateString
  firstObserved: ISO8601DateString
  mallUrl: string
  title: string
  externalId: string
  code: string
  mpn: string | null
  rate: number | null
  variationCount: number | null
  brand: Brand | null
  merchant: Merchant | null
  merchants: Merchant[]
  images: string[]
  createdAt: ISO8601DateString
  cmmf: string | null
  mall: string
  statuses: Status[]
}

export type ProductListItem = ProductInfo & {
  reviewFirstObserved: ISO8601DateString | null
  reviewLastObserved: ISO8601DateString | null
  questionFirstObserved: ISO8601DateString | null
  questionLastObserved: ISO8601DateString | null
  period: ProductListItemPeriod | null
  content: ContentStat | null
}

export type ListingDTO<T> = {
  total: number
  items: T[]
}

export type FilterPresetType = 'product_list' | 'keyword_list'

export type FilterPresetInfo = {
  id: number
  name: string
  type: FilterPresetType
  mall: string
}

export type FilterPreset = FilterPresetInfo & {
  data: object
  updatedAt: ISO8601DateString
  createdAt: ISO8601DateString
}

export type NewUser = {
  fullName: string
  email: string
  phone: string
  plainPassword: string
  authCode?: string
}

export type LoginData = {
  username: string
  password: string
}

export type FormValidationErrors = {
  error: 'FormValidationErrors'
  formErrors: { [key: string]: string[] }
}

export type AuthCodeType = 'call' | 'sms'

export type RegisterResponse =
  | { status: 'UserRegistered'; userId: number }
  | {
      status:
        | 'UserEmailExists'
        | 'UserPhoneExists'
        | 'AuthCodeMaxAttempts'
        | 'AuthCodeExpired'
        | 'AuthCodeInvalid'
    }

export type RegisterSendAuthCodeResponse =
  | { status: 'AuthCodeSent'; phone: string; type: AuthCodeType }
  | { status: 'AttemptsLimitReached'; phone: string; type: AuthCodeType; seconds: number }
  | { status: 'CaptchaFailed' }

export type LoginResponse =
  | { status: 'AuthSuccess' | 'InvalidCredentials' | 'UserBanned' }
  | { status: 'AuthNeedTwoFactor'; phone: string; type: AuthCodeType }
  | { status: 'AttemptsLimitReached'; seconds: number }

export type LoginSubmitAuthCodeResponse = {
  status:
    | 'AuthSuccess'
    | 'AuthCodeInvalid'
    | 'AuthCodeMaxAttempts'
    | 'AuthCodeExpired'
    | 'TwoFactorNotInProgress'
}

export type RecoverySendAuthCodeResponse =
  | {
      status: 'AuthCodeSent'
      phone: string
      type: AuthCodeType
    }
  | { status: 'AttemptsLimitReached'; seconds: number; phone: string; type: AuthCodeType }

export type RecoveryResetPasswordResponse =
  | {
      status: 'UserPasswordReset'
      phone: string
      username: string
      password: string
    }
  | { status: 'AuthCodeInvalid' | 'AuthCodeExpired' | 'AuthCodeMaxAttempts' }

export type PromoCode = {
  code: string
  freeDays: number
  tariffs: {
    mall: string
    pricePerMonth: number
    pricePer3Months: number
    pricePer6Months: number
    pricePerYear: number
  }[]
  updatedAt: ISO8601DateString
  createdAt: ISO8601DateString
}

export type PersonContactRequest = {
  phone: string
  name: string
}

export type CompanyContactRequest = {
  phone: string
  email: string
  name: string
  company: string
}

export type CategoryListFilter = {
  from: ISO8601DateString
  to: ISO8601DateString
  limit?: number
  after?: number
  categoryId?: number | null
  brandsIds?: number[]
  merchantsIds?: number[]
  sort?: ListSort<
    | 'sales'
    | 'gmv'
    | 'review'
    | 'brands_total'
    | 'rating_avg'
    | 'inventory_avg'
    | 'merchants_total'
    | 'products_avail'
    | 'products_sales'
  >
}

export type CategoryStatValue = {
  share: number | null
  brandsTotal: number | null
  merchantsTotal: number | null
  productsTotal: number | null
  productsObserved: number | null
  productsAvailable: number | null
  productsReviews: number | null
  productsSales: number | null
  availability: number | null
  sales: number | null
  gmv: number | null
  reviewCount: number | null
  ratingDistr: number[] | null
  wSoldPrice: number | null
  specificGmv: number | null
  specificSales: number | null
  inventoryAvg: number | null
  ratingAvg: number | null
  soldProductsShare: number | null
}

export type CategoryStatChartsQuery = {
  categoryId?: number
  from: ISO8601DateString
  to: ISO8601DateString
  brandsIds?: number[]
  merchantsIds?: number[]
  metric: CategoryStatChartType
}

export type CategoryStatCharts = Array<CategoryStatValue & { date: ISO8601DateString }>

export type CategoryStatChartType = keyof CategoryStatValue

export type CategoryStatWithGrowth = CategoryStatValue & { growth: CategoryStatValue }

export type CategoryStat = CategoryStatWithGrowth & {
  category: Category
}

export type CategoryList = {
  categories: CategoryStat[]
  current: Category
  total: CategoryStatWithGrowth
}

export type CategoryStatBrand = CategoryStatValue & {
  brand: Brand
  rank: number
  growth: CategoryStatValue
}

export type CategoryStatMerchant = CategoryStatValue & {
  merchant: Merchant
  rank: number
  growth: CategoryStatValue
}

export type CategoryPriceRange = {
  categoryId: number
  name: string
  priceFrom: number
  priceTo: number
  productsCount: number
  brandsCount: number | null
  merchantsCount: number | null
  gmv: number
}

export type Keyword = {
  suggest: string
  feedId: number
  phraseId: number
  rank: number
  rankDiff: number | null
  sales: number | null
  salesDiff: number | null
  gmv: number | null
  gmvDiff: number | null
  gmvSpecific: number | null
  gmvSpecificDiff: number | null
  salesSpecific: number | null
  salesSpecificDiff: number | null
  rating: number | null
  ratingDiff: number | null
  competition: number | null
  competitionDiff: number | null
  wSoldPrice: number | null
  wSoldPriceDiff: number | null
  weight: number | null
  searchVolume: number | null
  searchVolumeDiff: number | null
  totalProducts: number | null
  totalProductsDiff: number | null
  totalMerchants: number | null
  totalMerchantsDiff: number | null
  totalBrands: number | null
  totalBrandsDiff: number | null
  productsSold: number | null
  productsSoldDiff: number | null
  productsObserved: number | null
  productsObservedDiff: number | null
  reviews: number | null
  reviewsDiff: number | null
  lastObserved: ISO8601DateString
  categories: Category[]
  url: string | null
}

export type KeysWithDiff<T extends { [k: string]: any }> = keyof {
  [K in keyof T as `${string & K}Diff` extends keyof Keyword ? K : never]: number
}

type KeywordListSort<T extends string> = ListSort<T | `${T}_diff`>

export const keywordRangeFilters = [
  'totalProducts',
  //'productsObserved',
  //'productsSold',
  'sales',
  'gmv',
  'wSoldPrice',
  'searchVolume',
  //'competition',
  //'rank',
  'totalMerchants',
  //'totalBrands',
  'reviews',
  'rating',
  //'salesSpecific',
  //'gmvSpecific',
] as const

export type KeywordRangeFilterType = (typeof keywordRangeFilters)[number]

export type KeywordListFilter = {
  suggest?: string
  sort?: KeywordListSort<
    | 'suggest'
    | 'sales'
    | 'gmv'
    | 'search_volume'
    | 'rank'
    | 'total_products'
    | 'products_sold'
    | 'brands_total'
    | 'merchants_total'
    | 'rating'
    | 'competition'
    | 'w_sold_price'
    | 'sales_specific'
    | 'gmv_specific'
    | 'products_observed'
    | 'reviews'
  >
  phrasesIds?: number[]
  categoriesIds?: number[]
  merchantsIds?: number[]
  brandsIds?: number[]
  limit?: number
  after?: number
} & RangeFilter<KeywordRangeFilterType>

export type KeywordBrand = {
  suggest: string
  feedId: number
  brandId: number
  brandName: string
  rank: number | null
  sales: number | null
  gmv: number | null
  gmvSpecific: number | null
  salesSpecific: number | null
  rating: number | null
  wSoldPrice: number | null
  totalMerchants: number | null
  productsSold: number | null
  productsObserved: number | null
  reviews: number | null
}

export type KeywordMerchant = {
  suggest: string
  feedId: number
  merchantId: number
  merchantName: string
  rank: number | null
  sales: number | null
  gmv: number | null
  gmvSpecific: number | null
  salesSpecific: number | null
  rating: number | null
  wSoldPrice: number | null
  totalBrands: number | null
  productsSold: number | null
  productsObserved: number | null
  reviews: number | null
}

export type KeywordStatValue = {
  date: ISO8601DateString
  rank: number
  sales: number | null
  gmv: number | null
  gmvSpecific: number | null
  salesSpecific: number | null
  rating: number | null
  wSoldPrice: number | null
  searchVolume: number | null
  totalProducts: number | null
  totalMerchants: number | null
  totalBrands: number | null
  productsSold: number | null
  reviews: number | null
}

export type KeywordStatChartType = keyof Omit<KeywordStatValue, 'date'>

export type QuantileRequest = {
  metrics: KeysWithDiff<Keyword>[]
  quantiles: number[]
}

export type QuantileResponse = {
  metric: KeysWithDiff<Keyword>
  quantiles: number[]
}[]

export type QuantileData = Record<KeysWithDiff<Keyword>, number[]>

export type AiFeatureName = 'generate-description' | 'assistant'

export type CustomReport = {
  code: string
  title: string
  granularity: 'days' | 'months' | 'quarters'
  description: string
  fileName: string
}

export type CustomReportCategory = {
  title: string
  exports: CustomReport[]
}

export type ActionpayAction = 'confirmed_signup' | 'paid_order'

export const actionpayGoalIds: Record<ActionpayAction, number> = {
  confirmed_signup: 23092,
  paid_order: 23093,
} as const
